<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { ref, onBeforeMount, watch } from "vue";
import c3api from "../c3api";
import c3apiv2 from "../c3apiv2";
import { useToast } from "vue-toastification";
import fileDownload from "js-file-download";
// import {findParentNode} from "@tiptap/vue-3";
import { LicenseManager } from "ag-grid-enterprise";
import { useThemeStore } from "../stores/themeStore";
LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const themeStore = useThemeStore();
const toast = useToast();
let gridApi;
const statuses = ref([]);
const filter_status_id = ref(2);
const clients = ref([]);
const selectedClient = ref("");
const loadCount = ref(0);
const warehouses = ref([]);
const warehouseIds = ref([]);

const defaultColDef = ref({
  sortable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  suppressMenu: true,
  resizable: true,
  width: 120,
  filterParams: {
    maxNumConditions: 1,
    closeOnApply: true,
    filterOptions: ["contains"],
    buttons: ["apply"],
  },
});

const rangeFilterParams = {
  filterOptions: ["equals", "greaterThanOrEqual", "lessThanOrEqual", "inRange", "blank"],
  includeBlanksInLessThan: true,
  inRangeInclusive: true,
};

const allowBlankFilterParams = {
  filterOptions: ["contains", "blank"],
};

const valueGetters = {
  client_name: (params) => params.data?.client_name,
  eta: (params) => params.data?.eta?.substring(0, 10),
  warehouse: (params) => params.data?.warehouse_name,
  carrier: (params) => params.data?.carrier_name,
  received_at: (params) => params.data?.received_at?.substring(0, 10),
  status: (params) => params.data?.status_name,
};

const cellRenderers = {
  status: (params) => {
    return params && params.data && params.data.status_name
      ? `<div class="badge badge-pill badge-dark" style="background: ${params.data.status_color}">${params.data.status_name}</div>`
      : "";
  },
};

const colDefs = ref([
  { field: "number", headerName: "Inbound #", cellDataType: "text" },
  {
    field: "client_name",
    headerName: "Client",
    cellDataType: "text",
    width: 135,
  },
  { field: "po_number", headerName: "PO Number", cellDataType: "text", width: 200 },
  {
    field: "total_pieces",
    headerName: "Total Pcs.",
    cellDataType: "number",
    width: 115,
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    filterParams: rangeFilterParams,
  },
  {
    field: "container_number",
    headerName: "Container #",
    cellDataType: "text",
    width: 135,
  },
  { field: "vessel", headerName: "Vessel", cellDataType: "text", width: 110 },
  {
    field: "eta",
    headerName: "ETA",
    cellDataType: "dateString",
    filter: "agDateColumnFilter",
    filterParams: rangeFilterParams,
  },
  {
    field: "warehouse",
    headerName: "Warehouse",
    cellDataType: "text",
    width: 145,
  },
  {
    field: "carrier",
    headerName: "Carrier",
    cellDataType: "text",
    width: 130,
  },
  {
    field: "received_at",
    headerName: "Rec'd On",
    cellDataType: "dateString",
    filter: "agDateColumnFilter",
    filterParams: rangeFilterParams,
  },
  {
    field: "status",
    headerName: "Status",
    cellDataType: "text",
    filter: false,
    width: 100,
  },
]);

const addFuncsToColDefs = function (colDefs) {
  for (let def of colDefs) {
    let colName = def.colId;
    if (valueGetters[colName]) def.valueGetter = valueGetters[colName];
    if (cellRenderers[colName]) def.cellRenderer = cellRenderers[colName];
  }
};
addFuncsToColDefs(colDefs.value);

onBeforeMount(async () => {
  const filters = {
    filters: JSON.stringify([
      {
        column: "inbound",
        condition: "eq",
        value: true,
      },
    ]),
  };

  let pStatus = c3api
    .get("/order_statuses", { params: filters })
    .then((statusesResponse) => {
      console.log("statusesResponse:");
      console.log(statusesResponse.data?.data);
      if (statusesResponse.data.data) {
        statusesResponse.data.data.unshift({ id: 0, name: "All", short_label: "All" });
        statuses.value = statusesResponse.data.data;
      }
    });

  let pClients = c3api
    .get("/clients", { params: { page_size: 500 } })
    .then((clientsResponse) => {
      console.log("clientsResponse:");
      console.log(clientsResponse.data?.data);
      if (clientsResponse.data.data) {
        clients.value = clientsResponse.data.data;
      }
    });

  let pWarehouses = c3api.get("/warehouses").then((warehousesResponse) => {
    console.log("warehousesResponse:");
    console.log(warehousesResponse.data?.data);
    if (warehousesResponse.data.data) {
      warehouses.value = warehousesResponse.data.data;
      if (warehouseIds.value.length <= 0) {
        warehouseIds.value.push(warehouses.value[0].id);
      }
    }
  });

  await Promise.allSettled([pStatus, pClients, pWarehouses]).catch((error) => {
    console.log(error);
    toast.error(error);
  });
});

const onGridReady = (params) => {
  gridApi = params.api;
  reloadColDefs();
  reloadFilters();
  reloadColState();
};

const onRowClicked = function (event) {
  if (event.data.id) {
    gridApi.showLoadingOverlay();
    window.location = `/inbound-orders/${event.data.id}`;
  } else {
    console.log("Row clicked does not have an event.data.id!");
    console.log(event);
  }
};

const filtersChanged = function () {
  // console.log('filtersChanged');
  gridApi.onFilterChanged();
  saveFiltersToLocalStorage();
};

watch(warehouseIds, (warehouseIds) => {
  filtersChanged();
});

watch(filter_status_id, (filter_status_id) => {
  filtersChanged();
});

watch(selectedClient, (selectedClient) => {
  newInbound(selectedClient);
});

const onStateUpdated = function (params) {
  console.log("State updated", params.state);
  saveColStateToLocalStorage();
  saveColDefsToLocalStorage();
  saveFiltersToLocalStorage();
};

const connectDatasource = {
  getRows: async (params) => {
    loadCount.value++;
    gridApi.showLoadingOverlay();

    const urlParams = buildQueryParams(params);
    console.log("urlParams:");
    console.log(urlParams);

    try {
      const response = await c3apiv2.get("/inbound_orders", { params: urlParams });
      const data = JSON.parse(response.data[0]);
      console.log("/v2/inbound_orders data:");
      console.log(data);

      params.successCallback(data.orders, data.lastRow);
    } catch (error) {
      console.error(error);
      toast.error(error);
      params.failCallback();
    } finally {
      loadCount.value--;
      if (loadCount.value <= 0) {
        gridApi.hideOverlay();
      }
    }
  },
};

const gridOptions = {
  rowModelType: "infinite",
  datasource: connectDatasource,
  sideBar: {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
    // defaultToolPanel: 'columns',
  },
};

const advancedFilterKeys = ["total_pieces", "created_at", "eta", "received_at"];

const buildQueryParams = function (params, csv = false) {
  // console.log("buildQueryParams params:");
  // console.log(params);

  let warehouses = warehouseIds.value;
  // BRK TODO: can we make this a not hardcoded default?  Wait for initial warehouses load before requesting InboundOrders?
  if (warehouses.length < 1) warehouses = [1];

  const urlParams = {
    warehouses: warehouses,
    status_id: filter_status_id.value,
    sort: params.sortModel,
  };

  const filterParams = {};
  for (let [k, v] of Object.entries(params.filterModel)) {
    if (advancedFilterKeys.includes(k)) {
      filterParams[k] = v;
    } else {
      urlParams[k] = v.filter || v.dateFrom?.substring(0, 10);
    }
  }
  urlParams.filter = filterParams;

  if (typeof params.startRow !== "undefined") {
    urlParams.start_row = params.startRow;
    urlParams.limit = params.endRow - params.startRow;
  }
  if (csv === true) {
    urlParams.format = "csv";
  } else if (csv == true) {
    console.log("buildQueryParams csv param was truthy but not true: " + csv);
  }
  return urlParams;
};

const exportCsv = async function () {
  console.log("exportCsv");
  loadCount.value++;
  gridApi.showLoadingOverlay();

  const filterModel = gridApi.getFilterModel();
  let sortModel = gridApi.getColumnState().filter((column) => column.sort);
  sortModel = sortModel.map((item) => ({ colId: item.colId, sort: item.sort }));

  const params = {
    filterModel: filterModel,
    sortModel: sortModel,
  };

  const urlParams = buildQueryParams(params, true);
  try {
    const response = await c3apiv2.get("/inbound_orders", { params: urlParams });

    console.log("raw response:");
    console.log(response);
    const csv_data = response.data;

    const filename =
      response.headers["content-disposition"].match(/filename="?([^"]+)"?/)[1] ||
      "inbound_orders_export.csv";
    fileDownload(csv_data, filename);
  } catch (error) {
    console.error(error);
    toast.error(error);
  } finally {
    loadCount.value--;
    if (loadCount.value <= 0) {
      gridApi.hideOverlay();
    }
  }
};

const resetFilters = function () {
  // console.log('resetFilters');
  gridApi.setFilterModel(null);
};

const newInbound = function (clientId) {
  window.location.href = "/inbound-orders/new?client_id=" + clientId;
};

const setStatusFilter = function (id) {
  // console.log("setStatusFilter, id: #{id}");
  filter_status_id.value = id;
  filtersChanged();
};

const reloadFilters = function () {
  // console.log("reloadFilters");

  const saved = window.localStorage.getItem("inboundFilter");
  if (saved) {
    let filters = JSON.parse(saved);
    if (filters.filter_status_id !== 0) filters.filter_status_id ||= 2;
    if (typeof filters.warehouseIds == "undefined") filters.warehouseIds = { 0: 1 };
    filter_status_id.value = filters.filter_status_id;
    warehouseIds.value = filters.warehouseIds;
    gridApi.setFilterModel(filters.agGrid);
    return filters;
  } else {
    return {};
  }
};

const saveFiltersToLocalStorage = function () {
  // console.log("saveFiltersToLocalStorage");

  const filters = {
    agGrid: gridApi.getFilterModel(),
  };
  if (typeof filter_status_id.value != "undefined")
    filters.filter_status_id = filter_status_id.value;
  if (typeof warehouseIds.value != "undefined") filters.warehouseIds = warehouseIds.value;
  window.localStorage.setItem("inboundFilter", JSON.stringify(filters));
};

const reloadColState = function () {
  // console.log("reloadColState");
  let ret = {};

  let saved = window.localStorage.getItem("inboundColState");
  if (saved) {
    let savedColState = { state: JSON.parse(saved), defaultState: { sort: null } };
    gridApi.applyColumnState(savedColState);
    ret = savedColState;
  }
  return ret;
};

const saveColStateToLocalStorage = function () {
  // console.log("saveColStateToLocalStorage");
  const colState = gridApi.getColumnState();
  colState["defaultState"] = {
    // important to say 'null' as undefined means 'do nothing'
    sort: null,
  };
  window.localStorage.setItem("inboundColState", JSON.stringify(colState));
};

const reloadColDefs = function () {
  // console.log("reloadColDefs");
  let ret = {};

  const saved = window.localStorage.getItem("inboundColDefs");
  if (saved) {
    const savedColDefs = JSON.parse(saved);

    addFuncsToColDefs(savedColDefs);

    gridApi.setGridOption("columnDefs", savedColDefs);
    ret = savedColDefs;
  }
  return ret;
};

const saveColDefsToLocalStorage = function () {
  // console.log("saveColDefsToLocalStorage");
  const colDefs = gridApi.getColumnDefs();
  window.localStorage.setItem("inboundColDefs", JSON.stringify(colDefs));
};
</script>

<template>
  <div id="nav-app">
    <div class="top-nav flex-wrap" id="top-nav">
      <div class="d-flex justify-end">
        <div class="d-flex">
          <v-chip-group v-model="warehouseIds" multiple column mandatory>
            <v-chip
              small
              filter
              outlined
              :value="w.id"
              v-for="w in warehouses"
              :key="w.id"
            >
              {{ w.nickname }}
            </v-chip>
          </v-chip-group>
        </div>
      </div>
    </div>
  </div>
  <div id="inbound-orders-wrapper" class="text-on-surface">
    <header>
      <h5 class="mb-0 text-on-surface">Inbound</h5>
      <ul class="nav nav-pills cf">
        <li
          class="nav-item top-item text-on-surface"
          v-for="status in statuses"
          :key="status.id"
        >
          <a
            class="nav-link text-on-surface"
            :class="{
              'text-primary': status.id == filter_status_id,
              'border-b-lg': status.id == filter_status_id,
            }"
            href="#"
            @click="setStatusFilter(status.id)"
          >
            {{ status.short_label }}
          </a>
        </li>
      </ul>
      <div class="d-flex">
        <v-btn class="btn-header" @click="resetFilters">Reset</v-btn>
        <v-btn class="btn-header" @click="exportCsv">Export</v-btn>
        <v-dialog width="500">
          <template v-slot:activator="{ props }">
            <v-btn v-if="false" class="btn-header" v-bind="props" text="New"> </v-btn>
          </template>

          <template v-slot:default="{ isActive }">
            <v-card flat title="Create Inbound for:">
              <v-autocomplete
                label="Select Client"
                v-model="selectedClient"
                :items="clients"
                item-title="name"
                item-value="id"
                auto-select-first
              >
              </v-autocomplete>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn text="Close Dialog" @click="isActive.value = false"></v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
    </header>
    <AgGridVue
      style="width: 100%; height: 100%"
      :class="themeStore.agTheme"
      :columnDefs="colDefs"
      :defaultColDef="defaultColDef"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      @state-updated="onStateUpdated"
      @rowClicked="onRowClicked"
    ></AgGridVue>
  </div>
</template>

<style>
#inbound-orders-wrapper {
  width: 100%;
  height: 100%;

  & a {
    text-decoration: none;
  }

  & .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  & header {
    height: 50px;
    width: calc(100vw - 65px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: transparent;
    border-bottom: 1px solid #cfcfcf;
    padding: 0 1rem;
    z-index: 2;
  }

  & header h5 {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 0;
    font-size: 1.25rem;
  }

  & header .nav-pills .nav-link {
    background: transparent;
    padding: 0 1rem;
    border-radius: 0;
    border-bottom: 2px solid transparent;
    display: flex;
    color: inherit;
    justify-content: center;
    align-items: center;
  }

  & header .nav-pills .nav-link.active {
    background: transparent;
    color: inherit;
    border-bottom: 2px solid #1a66c0;
  }

  & header .btn {
  }

  & .btn-header,
  .btn-header:visited,
  .btn-header:active {
    height: 49px;
    padding: 0 1rem;
    border: 0;
    border-left: 1px solid #cfcfcf;
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    background-color: rgba(255, 255, 255, 0.2);
    text-decoration: none;
    color: #4691f6;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0;
    border-right: 1px solid transparent;
  }

  & .btn-header:last-child,
  .btn-header:visited:last-child,
  .btn-header:active:last-child {
    border-right: 1px solid #cfcfcf;
  }

  & .btn-header:focus {
    outline: 0;
  }

  & .btn-header:hover {
    text-decoration: none;
    background-color: #1a66c0;
    color: #fff;
  }

  & .btn-header:hover .svg-icon path,
  .btn-header:hover .svg-icon polygon,
  .btn-header:hover .svg-icon rect {
    fill: #fff;
  }

  & .btn-header:hover .svg-icon circle {
    stroke: #fff;
  }

  & .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  & .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
  }

  & .badge-dark {
    color: #fff;
    background-color: #343a40;
  }
}
</style>
